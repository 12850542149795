
<template>
     <div>
          <zr-form-designer  @handleSave="handleSave" />
     </div>
</template>


<script>
     import  {ZrFormDesigner} from "../../../../components/ZrCustomizeForm";
    export default {
        name: "designer",
         components: {
              ZrFormDesigner
         },
         methods: {
              handleSave(val) {
                   this.$message.success("触发保存方法");
                   console.log(val);
              }
         }
    }
</script>

<style scoped>

</style>
import { ZrFormDesign } from "@/components";
